






























































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import {
  differentAgeConsumeDetails,
  marketPenetration,
} from '@/utils/echarts/echarts';
import countUpFn from '@/utils/countUp';
import { marketPenetrationSvc } from '@/services/api';
import { deepClone } from '@/utils';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
import portraitPrivcar from '@/components/portraitPrivcar/index.vue';
/* eslint-disable @typescript-eslint/naming-convention*/

@Component({
  components: {
    compareCard,
    tdesignFooter,
    portraitPrivcar,
    spinCard,
  },
})
export default class MarketPenetration extends Vue {
  $dayjs: any;
  compareTips = '无法与昨日对比';
  order = true;
  excelArr: any[] = [];
  mallTotalStatus = false;
  comparetitle = '较昨日';
  pravcar = Vue.prototype.$GLOBAL.privacyInfo;
  reset = false;
  show = true;
  mallId = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  duration = this.$store.state.mall.duration;
  touristNumOptions = {};
  displayDate = {
    obj: {
      pop_sum: 0,
      source_sum: 0,
      percent: 0,
    },
    man_obj: {},
    men_obj: {},
    data: [],
  };
  pastDate = {
    obj: {
      pop_sum: 0,
      source_sum: 0,
      percent: 0,
    },
    man_obj: {},
    men_obj: {},
    data: [],
  };
  popsumbefore = 0;
  sourcesumbefore = 0;
  peopleNumOptions = {};
  slotEcharts = false;
  slotcontent = true;
  marketOptions = {};
  arr = [''];
  maleArr = [{}];
  femaleArr = [{}];

  get popSumStatus(): boolean {
    const text = this.popSumText;
    return Number(text) < 0;
  }

  get popSumText(): string {
    // 占比
    // return computePassengerFlowProfile(String(this.pastDate.obj.pop_sum), String(this.displayDate.obj.pop_sum));
    // 人数
    return String(this.displayDate.obj.pop_sum - this.pastDate.obj.pop_sum);
  }

  get sourceNumStatus(): boolean {
    const text = this.sourceNumText;
    return Number(text) < 0;
  }

  get sourceNumText(): string {
    // 占比
    // return computePassengerFlowProfile(
    //   String(this.pastDate.obj.source_sum),
    //   String(this.displayDate.obj.source_sum),
    // );
    // 人数
    return String(this.displayDate.obj.source_sum - this.pastDate.obj.source_sum);
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  @Watch('$store.state.mall', { immediate: true, deep: true })
  mallInfoChange(): void {
    this.renderData();
  }
  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const dateObj = {
      d: this.date,
      xw: `${this.$dayjs(this.date).subtract(6, 'day')
        .format('YYYY-MM-DD')}~${this.date}`,
      xm: this.$dayjs(this.date).format('YYYY-MM'),
    };
    if (this.displayDate?.data) {
      this.displayDate.data.forEach((i: any) => {
      // eslint-disable-next-line no-param-reassign
        i.date = (dateObj as any)[this.duration];
      });
    }
    const people: any = this.displayDate?.obj ? [this.displayDate.obj] : [];
    const female: any = this.displayDate?.men_obj ? [this.displayDate.men_obj] : [];
    const male: any = this.displayDate?.man_obj ? [this.displayDate.man_obj] : [];
    if (people.length) {
      people.forEach((i: any) => {
        // eslint-disable-next-line no-param-reassign
        i.time = (dateObj as any)[this.duration];
      });
    }

    if (female.length) {
      female.forEach((i: any) => {
      // eslint-disable-next-line no-param-reassign
        i.time = (dateObj as any)[this.duration];
      });
    }
    if (male.length) {
      male.forEach((i: any) => {
      // eslint-disable-next-line no-param-reassign
        i.time = (dateObj as any)[this.duration];
      });
    }
    (this.displayDate.man_obj as any).date = this.date;
    (this.displayDate.men_obj as any).date = this.date;
    (this.displayDate.obj as any).date = this.date;
    const datas = [];
    const status = this.$store.state.global.downloadStatus;

    if (status === true) {
      datas.push({
        sheetData: this.displayDate?.data ? this.displayDate.data : [],
        sheetName: '客群性别年龄分布',
        sheetMultiHeader: [
          ['数据项:商圈覆盖-市场渗透'],
        ],
        sheetFilter: ['date', 'text', 'percent', 'pop', 'num'],
        sheetHeader: ['日期', '性别', '市场渗透率', '人口数', '客流人数'],
        columnWidths: [8, 12, 12, 12, 12],
      });
      datas.push({
        sheetData: people,
        sheetName: '核心数据-总人口',
        sheetMultiHeader: [
          ['数据项:商圈覆盖-市场渗透'],
        ],
        sheetFilter: ['time', 'percent', 'source_sum', 'pop_sum'],
        sheetHeader: ['日期', '渗透率', '客流数', '总人口数'],
        columnWidths: [8, 12, 12, 12],
      });
      datas.push({
        sheetData: female,
        sheetName: '核心数据-女性人口',
        sheetMultiHeader: [
          ['数据项:商圈覆盖-市场渗透'],
        ],
        sheetFilter: ['time', 'percent', 'source_sum', 'pop_sum'],
        sheetHeader: ['日期', '渗透率', '客流数', '总人口数'],
        columnWidths: [8, 12, 12, 12],
      });
      datas.push({
        sheetData: male,
        sheetName: '核心数据-男性人口',
        sheetMultiHeader: [
          ['数据项:商圈覆盖-市场渗透'],
        ],
        sheetFilter: ['time', 'percent', 'source_sum', 'pop_sum'],
        sheetHeader: ['日期', '渗透率', '客流数', '总人口数'],
        columnWidths: [8, 12, 12, 12],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  async renderData(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.show = true;
    this.mallId = this.$store.state.mall.mallId;
    this.date = this.$store.state.mall.date;
    this.duration = this.$store.state.mall.duration;
    const params = {
      mall_id: this.mallId,
      date: this.date,
      num_type: 20, // 固定值
      pop_type: 21, // 固定值
      duration: this.duration, // 固定值
    };
    const res = await marketPenetrationSvc(params);
    this.displayDate = res;
    let days;
    if (this.duration === 'd') {
      this.comparetitle = '较昨日';
      this.compareTips = '无法与昨日对比';
      days = 'day';
    } else if (this.duration === 'xw') {
      this.comparetitle = '较上周';
      this.compareTips = '无法与上周对比';
      days = 'week';
    } else if (this.duration === 'xm') {
      this.comparetitle = '较上月';
      this.compareTips = '无法与上月对比';
      days = 'month';
    }
    const lastDate = this.$dayjs(this.date)
      .subtract(1, days)
      .format('YYYY-MM-DD');
    const pastParams = {
      mall_id: this.mallId,
      date: lastDate,
      num_type: 20, // 固定值
      pop_type: 21, // 固定值
      duration: this.duration, // 固定值
    };
    const res1 = await marketPenetrationSvc(pastParams, false);

    this.pastDate = res1;
    if (
      this.displayDate.obj.pop_sum !== this.popsumbefore
      && this.displayDate.obj.source_sum !== this.sourcesumbefore
    ) {
      countUpFn(
        'box1',
        this.displayDate.obj.pop_sum ? this.displayDate.obj.pop_sum : 0,
      );
      countUpFn(
        'box2',
        this.displayDate.obj.source_sum ? this.displayDate.obj.source_sum : 0,
      );
      this.popsumbefore = this.displayDate.obj.pop_sum;
      this.sourcesumbefore = this.displayDate.obj.source_sum;
    }

    this.initCharts(this.displayDate);
    this.show = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  initCharts(params: any): void {
    this.show = false;
    this.arr = [];
    this.maleArr = [];
    this.femaleArr = [];

    params.data.forEach((i: any) => {
      this.arr.push(i.text.split('(')[1].split(')')[0]);
      if (i.text.includes('男')) {
        this.maleArr.push(i);
      } else {
        this.femaleArr.push(i);
      }
    });
    if (!this.reset) {
      this.marketOptions = differentAgeConsumeDetails(
        this.arr.slice(0, this.arr.length / 2),
        this.maleArr.map(i => (i as any).percent),
        this.femaleArr.map(i => (i as any).percent),
        3,
        '年龄',
        '市场渗透率（%)',
        '男性',
        '女性',
      );
      this.peopleNumOptions = differentAgeConsumeDetails(
        this.arr.slice(0, this.arr.length / 2),
        this.maleArr.map(i => (i as any).pop),
        this.femaleArr.map(i => (i as any).pop),
        3,
        '年龄',
        '人口数',
        '男性',
        '女性',
      );
      this.touristNumOptions = differentAgeConsumeDetails(
        this.arr.slice(0, this.arr.length / 2),
        this.maleArr.map(i => (i as any).num),
        this.femaleArr.map(i => (i as any).num),
        3,
        '年龄',
        '客流人数',
        '男性',
        '女性',
      );
    } else {
      const displaypercent = deepClone(this.displayDate);
      const displaypercentTwo = deepClone(this.displayDate);
      const displaypop = deepClone(this.displayDate);
      const displaypopTwo = deepClone(this.displayDate);
      const displaynum = deepClone(this.displayDate);
      const displaynumTwo = deepClone(this.displayDate);
      this.orderDataRender(displaypercent, displaypercentTwo, 'percent');
      this.orderDataRender(displaypop, displaypopTwo, 'pop');
      this.orderDataRender(displaynum, displaynumTwo, 'num');
      this.marketOptions = marketPenetration(
        displaypercent.data.map((i: any) => i.text),
        displaypercent.data.map((i: any) => i.percent),
        displaypercentTwo.data.map((i: any) => i.percent),
        '女性',
        '男性',
        '市场渗透率(%)',
        2,
      );
      this.peopleNumOptions = marketPenetration(
        displaypop.data.map((i: any) => i.text),
        displaypop.data.map((i: any) => i.pop),
        displaypopTwo.data.map((i: any) => i.pop),
        '女性',
        '男性',
        '人口数',
        1,
      );
      this.touristNumOptions = marketPenetration(
        displaynum.data.map((i: any) => i.text),
        displaynum.data.map((i: any) => i.num),
        displaynumTwo.data.map((i: any) => i.num),
        '女性',
        '男性',
        '客流人数',
        1,
      );
    }
  }
  inOrder(): void {
    this.reset = !this.reset;
    this.renderData();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  orderDataRender(val1: any, val2: any, sortVal: any): void {
    val1.data.sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val2.data.sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val1.data.forEach((i: any) => {
      if (i.text.includes('男')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
    val2.data.forEach((i: any) => {
      if (i.text.includes('女')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
  }
}
